div.oke-w-reviewer-verified{
  color:#757575 !important;
}

label.hidden-label[for^="newsletter-form"]{
  background-color: rgba(0,0,0,0.005) !important;
}

label.hidden-label[for^="Email-template"]{
   background-color: rgba(0,0,0,0.005) !important;
}

blockquote.testimonials-slider__text span.testimonial-stars{
   background-color: rgba(0,0,0,0.005) !important;
}

div.oke-reviewContent-date span{
  color:#757575 !important;
}

div.oke-helpful span.oke-helpful-label{
    color:#757575 !important;
}

div.okeReviews .oke-helpful-vote-button {
   color:#757575 !important;
}

div.oke-w-breakdownModule-distribution-count{
    color:#757575 !important;
}

div.oke-w-ratingAverageModule-count{
    color:#757575 !important;
}

span.oke-w-recommendsModule-text{
    color:#757575 !important;
}

div.oke-w-reviews-count{
      color:#757575 !important;
}

div.product-benefits-head-right span>p{
  background-color: rgba(0,0,0,0.005) !important;
}

div.timesact-badge-ribbon-preorder p {
   background-color: rgba(0,0,0,0.005) !important;
}

div.faqtop-inner h2{
  background-color: rgba(0,0,0,0.005) !important;
}

.pagination .next,.pagination .prev{
  background-color: rgba(0,0,0,0.005) !important;
}

div.pagination span.page.current{
   background-color: rgba(0,0,0,0.005) !important;
}

#rebuy-smart-cart-announcement-bar div.rebuy-cart__flyout-announcement-bar-message.splide__slide#rebuy-smart-cart-announcement-bar-slide01{
  background-color: rgba(0,0,0,0.005) !important;
}

span.rebuy-money.compare-at span{
    background-color: rgba(0,0,0,0.005) !important;
}